import React from 'react'
import { Link } from 'react-router-dom'

// Third party
import { motion } from 'framer-motion'
import { IonList } from '@ionic/react'
import { KeyboardBackspace } from '@mui/icons-material'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import clsx from 'clsx'
import ViewsLoader from '../../loader/ViewsLoader'
import './WrapperContainer.css'

// Types
type IWithoutCustomHeader = {
  headLink?: string
  loading?: boolean
  isError?: boolean
  title?: string
  className?: string
  children: ReactJSXElement
  noBg?: boolean
  wrapperStyles?: Record<any, any>
  removeShadow?: boolean
  removePadding?: boolean
  customHeader?: ReactJSXElement
  headerStyles?: React.CSSProperties
  hideTitle?: boolean
  onGoBackButtonClick?: (args?: any) => any
}

type IWithCustomHeader = {
  headLink?: string
  title?: string
  loading?: boolean
  children: ReactJSXElement
  noBg?: boolean
  className?: string
  isError?: boolean
  wrapperStyles?: Record<any, any>
  removeShadow?: boolean
  removePadding?: boolean
  customHeader: ReactJSXElement
  headerStyles?: React.CSSProperties
  hideTitle?: boolean
  onGoBackButtonClick?: (args?: any) => any
}

type IProps = IWithoutCustomHeader | IWithCustomHeader

/**
 * @info Wraps the component
 * @returns jsx component
 */
const WrapperContainer = (props: IProps) => {
  let childrenElemClasses = `mb-[77px] ${['Bills'].find((data) => data === props.title) ? ' h-[calc(98vh-131px)] ' : ' '} !2xl:mx-[45px] mx-4 !md:mx-[25px] min-h-[85vh] !bg-white ${props?.noBg === true ? 'bg-transparent ' : 'bg-white '}${props?.removeShadow ? '' : 'home_root_ion_list '}${props?.removePadding ? 'p-0 ' : 'p-5 '}rounded-[20px]`
  let headerStyles = props?.headerStyles ?? {}

  childrenElemClasses = clsx(
    childrenElemClasses,
    !props?.title && '!mt-0',
    (props.loading || props.isError) && 'flex items-center',
    props.className
  )

  return (
    <motion.div
      className="w-full mx-[0px] p-[20px]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 0.5,
      }}
    >
      {props?.customHeader ? (
        props.customHeader
      ) : props?.title ? (
        <div
          className={clsx(
            `text-base md:text-[24px] 2xl:p-3 2xl:pl-[45px] p-3 md:px-[30px] px-[20px] text-SeabiscuitDark200ThemeColor font-bold wrapper_container`,
            props?.title === 'My Events' && 'mt-14'
          )}
          style={headerStyles}
        >
          {!props?.hideTitle ? (
            props.headLink ? (
              <Link
                to={props.headLink}
                onClick={() => props?.onGoBackButtonClick?.()}
                className="transition-all hover:opacity-70"
              >
                <KeyboardBackspace className="text-SeabiscuitDark200ThemeColor mr-2" />
                {props?.title}
              </Link>
            ) : (
              props?.title ?? ''
            )
          ) : null}
        </div>
      ) : null}
      <IonList className={childrenElemClasses}>
        {props.loading ? (
          <ViewsLoader className="flex items-center w-full justify-center" size="lg" color="red" />
        ) : (
          props.children
        )}
      </IonList>
    </motion.div>
  )
}

export default WrapperContainer
